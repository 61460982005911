/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import { sessionStorage } from "../../../../../assets/myjs/glovar.js";
export default {
  name: "ywTrackInfo",
  data() {
    return {
      ywTrackInfoData: {}
      //   ywTrackInfoData: {
      //     fullname: "了对接肯定凯撒奖到里面撒开聊大幅扫卡了",
      //   },
    };
  },

  created() {
    var ywTrackInfoData = sessionStorage.getItem("ywTrackdata");
    console.log(JSON.parse(ywTrackInfoData));
    this.ywTrackInfoData = JSON.parse(ywTrackInfoData);
  },
  methods: {
    onClickLeft() {
      this.$router.push("/ywTrack");
      sessionStorage.removeItem("ywTrackdata");
    }
  }
};